html { -webkit-tap-highlight-color:rgba(0,0,0,0); -webkit-tap-highlight-color: transparent; }
body { background:#fff; padding:0; margin:0; font-family: 'Source Sans Pro', sans-serif; text-align:center; color:#445; font-size:18px; line-height:26px; }
input, textarea, select, option { font-family: 'Source Sans Pro', sans-serif; outline:none; border:1px solid #888; }

.front-wrapper { width:100%; height:100vh; background:#000; }
.front-shadow { position:absolute; left:0; top:0; width:100%; height:100vh; background-image:linear-gradient( rgba(0,0,0,0.3), rgba(0,0,0,0.6) ) }

.front { position:absolute; width:100%; left:0; top:50%; padding:0 10px 20px 10px; text-align:left; font-weight:400; text-shadow:0 5px 10px rgba(0,0,0,0.4); }
.front-arrow-down { position:absolute; bottom:0; left:50%; padding:20px 40px 60px 40px; user-select:none; transform:translateX(-50%); font-size:36px; text-shadow:0 5px 10px rgba(0,0,0,0.4); }

.front-link-hover { border-bottom:2px solid rgba(255,255,255,0); }
.front-link-hover:hover { border-bottom:2px solid rgba(255,255,255,1); }

.button-front { border:2px solid white; width:100px; border-radius:4px; padding:8px 16px; font-weight:700; margin:10px; text-decoration:none; color:white; text-transform:uppercase; font-size:12px; letter-spacing:2px; }
.button-front-hover:hover { background:rgba(255,255,255,0.8); color:#444; }

h1 { font-weight:400; font-size:38px; margin-top:40px; }
h2 { font-weight:400; }
h3 { font-weight:400; }
h4 { font-weight:400; }
strong { font-weight:400; padding:5px 0 5px 0; color:#000; }
h2 { font-weight:400; font-size:22px; }
p { font-size:15px; }
.pointer { cursor:pointer; }

.info-wrapper { margin: 0; background:#e7e7e7; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.info { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }

.two { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }
.three { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }
.four { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px 0 10px; vertical-align:top; }


.all-activities-tabs { height:40px; padding:6px 10px 0 10px; margin:2px; box-sizing:border-box; font-size:12px; user-select:none; font-weight:500; background-color:#efefef; cursor:pointer; border-bottom:5px solid #eee; }
.all-activities-tabs:hover { border-bottom:5px solid #00e5ff; }
.all-activities-tabs-selected { border-bottom:5px solid #00e5ff; }

.all-activities-pills { display:inline-block; margin:2.5px; padding:0 10px; border-radius:14px; border:1px solid black; font-size:12px; user-select:none; font-weight:700; cursor:pointer; }
.all-activities-pills:hover { background-color:#bbdefb; color:black; }
.all-activities-pills-selected { background-color:#1e88e5; color:white; }
@media only screen and ( min-width: 657px) {
	.all-activities-pills { width:95px; margin:5px 12.5px 5px 5px; padding:0 15px; }
	.all-activities-pills:hover { background-color:#bbdefb; color:black; }
	.all-activities-pills-selected { background-color:#1e88e5; color:white; }
}

.categories-list-block { display:inline-block; width:100%; height:290px; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
@media only screen and ( min-width: 657px) {
	.categories-list-block { width:270px; height:290px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
}

.activities-list-block { display:inline-block; width:100%; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
@media only screen and ( min-width: 657px) {
	.activities-list-block { width:270px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
}

.blogs-list-block { display:inline-block; width:100%; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
@media only screen and ( min-width: 657px) {
	.blogs-list-block { width:270px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
}

.card-hover { table-layout:fixed; border-collapse:collapse; width:100%; box-sizing:border-box; box-shadow:0 3px 6px rgba(0,0,0,0.15); }
.card-hover:hover { box-shadow:0 6px 6px rgba(0,0,0,0.15); transform:translateY(-1px); }

.products-wrapper { margin: 0; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.products { margin: auto; width:100%; padding:0 0 20px 0; max-width:1240px; box-sizing:border-box; }
.products-flex-box { display:block; }
.products-checkout { position:relative; width:100%; height:1572px; padding:20px; box-shadow:0 3px 6px rgba(0,0,0,0.2); background:white; margin:0; box-sizing:border-box; }
.products-checkout-component { position:relative; width:100%; padding:20px; box-shadow:0 3px 6px rgba(0,0,0,0.2); background:white; margin:0; box-sizing:border-box; }

.banner-parent { width:100%; height:300px; overflow:hidden; cursor:'pointer' }
.banner-child { width:100%; height:100%; transition: all 1.2s; }
.banner-img { object-fit:cover; width:100%; height:100%; }
.banner-parent:hover .banner-child, .banner-parent:focus .banner-child { transform: scale(1.025); }
.banner-child::before { content: ""; display: none; height: 100%; width: 100%; position: absolute; top: 0; left: 0; background-image:linear-gradient( rgba(0,0,0,0.05), rgba(0,0,0,0.25) );  }
.banner-parent:hover .banner-child:before,
.banner-parent:focus .banner-child:before { display: block; }

.gallery-background { position:fixed; z-index:2; left:0; top:0; width:100%; height:100%; background:#333; box-sizing:border-box; user-select:none; }
.gallery-wrapper { position:absolute; user-select:none; left:50%; top:50%; transform:translate( -50%, -50% ); width:100%; height:100%; padding:0 0 20px 0; max-width:1240px; box-sizing:border-box; }
.gallery-image { position:absolute; backface-visibility:hidden; user-select:none; left:0; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-left { position:absolute; backface-visibility:hidden; user-select:none; left:-100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-right { position:absolute; backface-visibility:hidden; user-select:none; left:100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }

.gallery-close { position:absolute; right:0; top:0; cursor:pointer; width:50%; user-select:none; color:white; font-size:32px; }
.gallery-close > i { position:fixed; right:10px; top:10px; padding:20px; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-close > i:hover { background-color:rgba(0,0,0,0.5); }

.gallery-arrow-left { position:absolute; left:0; top:50%; transform:translateY(-50%); cursor:pointer; width:110px; height:120px; user-select:none; color:white; font-size:32px; }
.gallery-arrow-left > i { position:fixed; left:10px; top:50%; transform:translateY(-50%); padding:20px; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-left > i:hover { background-color:rgba(0,0,0,0.5); }

.gallery-arrow-right { position:absolute; right:0; top:50%; transform:translateY(-50%); cursor:pointer; width:110px; height:120px; user-select:none; color:white; font-size:32px; }
.gallery-arrow-right > i { position:fixed; right:10px; top:50%; transform:translateY(-50%); padding:20px; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-right > i:hover { background-color:rgba(0,0,0,0.5); }

.footer-wrapper { margin:0; width:100%; text-align:left; font-size:13px; padding:20px 0 10px 0; background-color:#263238; color:white; vertical-align:top; box-sizing:border-box;  }
.footer { margin:auto; width:100%; max-width:1240px; padding:30px; vertical-align:top; box-sizing:border-box;  }
.footer-flex-box { display:block; }
.footer-item-group { width:270px; min-width:270px; padding-right:20px; padding-bottom:20px; }

.mobile-only { display:block; }
.desktop-only { display:none; }

@media only screen and ( min-width: 768px ) and ( min-height: 412px ) {
	.info { width:49%; }
	.two { width:49%; }
	.three { width:32%; }
	.four { width:24%; }
	.products-flex-box { display:flex; }
	.products-checkout { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	.products-checkout-component { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	.footer-flex-box { display:flex; }
	.footer-item-group { width:270px; padding-right:20px; }
	.mobile-only { display:none; }
	.desktop-only { display:block; }
}

a { text-decoration:none; color:black; }
a:hover {  }


.shadow { -webkit-filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); }


.picker {
  position: fixed !important;
  top: 74px;
  left: 70px;
  width: 90px !important;
  height: 90px !important;
}
